<template>
  <div>
    <div class="mb-3"><h1>Request To Join Company</h1></div>

    <div class="alert alert-warning" v-if="isLoading">
      Loading...
    </div>

    <form class="mb-3" @submit.prevent v-else>
      <div class="alert alert-success" v-if="isCompleted">
        Your request has been submitted and an email has been sent.
      </div>

      <div class="alert alert-danger" v-if="error">
        {{ error }}
      </div>

      <div class="alert alert-info" v-if="isInvalidCompany">
        You are attempting to join an invalid company, and you will not be able to submit the form.
      </div>

      <div v-else>
        <div class="form-group row mb-0">
          <label for="displayCompanyName" class="col-sm-2 col-form-label">Company</label>
          <div class="col-sm-10">
            <input type="text" readonly disabled class="form-control-plaintext" id="displayCompanyName" :value="company.name">
          </div>
        </div>

        <div class="form-group row" v-if="company.email">
          <label for="displayCompanyEmail" class="col-sm-2 col-form-label">Email</label>
          <div class="col-sm-10">
            <input type="text" readonly disabled class="form-control-plaintext" id="displayCompanyEmail" :value="company.email">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="row form-title d-flex align-items-center ml-0 mb-4">
            <h2 class="m-0">User Information</h2>
          </div>
          <div class="row d-flex justify-content-end align-items-center">
            <span class="red-dot mr-2"></span> = Required
          </div>

          <div class="form-row">
            <div class="col-md-6 mb-3">
              <label for="userFirstName">First name <span class="red-dot ml-3"></span></label>
              <input id="userFirstName" name="userFirstName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('userFirstName') }"
                autocomplete="off" placeholder="Enter first name" v-validate="'required|max:255'" v-model="user.firstName" data-vv-as="First Name" />
              <div class="invalid-feedback">{{ errors.first('userFirstName') }}</div>
            </div>

            <div class="col-md-6 mb-3">
              <label for="userLastName">Last name <span class="red-dot ml-3"></span></label>
              <input id="userLastName" name="userLastName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('userLastName') }"
                autocomplete="off" placeholder="Enter Last name" v-validate="'required|max:255'" v-model="user.lastName" data-vv-as="Last Name" />
              <div class="invalid-feedback">{{ errors.first('userLastName') }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="userEmail">Email <span class="red-dot ml-3"></span></label>
            <input id="userEmail" name="userEmail" type="text" class="form-control" :class="{ 'is-invalid': errors.has('userEmail') }"
              autocomplete="off" placeholder="Enter email address" v-validate="'required|email'" v-model="user.email" data-vv-as="Email" />
            <div class="invalid-feedback">{{ errors.first('userEmail') }}</div>
          </div>

          <div class="form-row">
            <div class="col-md-6 mb-3">
              <label for="userPassword">Password <span class="red-dot ml-3"></span></label>
              <input id="userPassword" name="userPassword" type="password" class="form-control" :class="{ 'is-invalid': errors.has('userPassword') }"
                autocomplete="off" placeholder="Enter password" v-validate="{ required: true, min:8, max:50 }" v-model="user.password"
                data-vv-as="Password" ref="password" />
              <div class="invalid-feedback">{{ errors.first('userPassword') }}</div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="userPasswordConfirm">Confirm Password <span class="red-dot ml-3"></span></label>
              <input id="userPasswordConfirm" name="userPasswordConfirm" type="password" class="form-control" :class="{ 'is-invalid': errors.has('userPasswordConfirm') }"
                autocomplete="off" placeholder="Enter Password Confirmation" v-validate="'required|confirmed:password'"
                data-vv-as="Confirm Password" />
              <div class="invalid-feedback">'Password' and 'Confirm Password' do not match.</div>
            </div>
          </div>

          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="userEmailOptOut" v-model="user.emailOptOut">
            <label class="form-check-label" for="userEmailOptOut">Opt out from daily email</label>
          </div>

          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="userDirectoryListAsContact" v-model="user.listAsDirectoryContact">
            <label class="form-check-label" for="userDirectoryListAsContact">List me as directory contact</label>
          </div>
        </div>
      </div>



      <div class="d-flex flex-row-reverse bd-highlight">
        <button type="submit" class="btn btn-success ld-ext-right" :class="{ 'running' : isSubmitting }" @click="onSubmit">
          <span v-if="isSubmitting">Submitting...</span>
          <span v-else>Submit</span>
          <div class="ld ld-ring ld-spin"></div>
        </button>
        <button type="button" class="btn btn-primary mr-2" @click="cancel">Cancel</button>
      </div>

    </form>
  </div>
</template>

<script>
import { CompanyService } from '@/services/';

export default {
  name: 'public-company-join',
  data: function () {
    return {
      company: null,
      error: null,
      user: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        emailOptOut: false,
        listAsDirectoryContact: true
      },
      isCompleted: false,
      isInvalidCompany: false,
      isLoading: true,
      isSubmitting: false
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'signup-company-lookup' });
    },
    async onSubmit() {
      this.error = null;
      this.isCompleted = false;

      if (this.isInvalidCompany) {
        return;
      }

      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.isSubmitting = true;
      let payload = { alias: this.$route.params.slug, ...this.user };

      CompanyService.signupJoin(payload).then(() => {
        this.isSubmitting = false; this.isCompleted = true;
      })
      .catch(err => {
        this.isSubmitting = false;

        let errorMessageData = err.response.data;
        if (errorMessageData && errorMessageData.innerException && errorMessageData.innerException.exceptionMessage)
          this.error = errorMessageData.innerException.exceptionMessage;
      });
    }
  },
  async mounted () {
    this.user.email = this.$store.state.global.publicSignupVerifyEmail || '';

    await CompanyService.signupCompanyLookup(this.$route.params.slug)
      .then(({ data }) => {
        this.company = data;
        this.isInvalidCompany = !data;
        this.isLoading = false;
      });
  }
}
</script>
